import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import Extra from "./Extra";
import Register from "./Register";
// import { AuthProvider } from './AuthContext';
// import ProtectedRoute from './ProtectedRoute';
import { AuthProvider } from "./AuthContext.js";
import Login from "./Login";

function App() {
  return (
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Login />} />
    //     <Route path="/extra" element={<Extra />} />
    //     <Route path="/register" element={<Register />} />
    //   </Routes>
    // </Router>

    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/extra" element={<Extra />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
