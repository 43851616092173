// src/AuthContext.js
import React, { createContext, useState, useContext } from 'react';

// Create the context for storing the token
const AuthContext = createContext();

// Create the AuthProvider component that will wrap the application
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null); // Store the token here

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);
