import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../src/sitemap_bg.webp";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [errors, setErrors] = useState({ username: "", password: "", cpassword: "" });

  const navigate = useNavigate();
  const Url = "https://aihumanizer.pranathiss.com/qxbox";

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setErrors({ username: "", password: "", cpassword: "" });

    let validationErrors = {};

    // Validate username
    if (username.length < 3 || username.length > 150) {
      validationErrors.username = "Username must be between 3 and 150 characters.";
    }

    // Validate password
    if (password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters long.";
    }

    // Validate confirm password
    if (password !== cpassword) {
      validationErrors.cpassword = "Passwords do not match.";
    }

    // If client-side validation fails, set errors and stop submission
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Create FormData object
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password1", password);
    formData.append("password2", cpassword);

    try {
      const response = await axios.post(`${Url}/signup/`, formData);

      // On successful signup, redirect
      navigate("/");
    } catch (error) {
      if (error.response && error.response.data) {
        const apiErrors = error.response.data.details || {};
        const newErrors = {};

        // Map API errors to the corresponding fields
        if (apiErrors.username) {
          newErrors.username = apiErrors.username.join(" ");
        }
        if (apiErrors.password2) {
          newErrors.password = apiErrors.password2.join(" ");
        }

        setErrors(newErrors); // Update errors state with API validation messages
      } else {
        console.error("Error during signup:", error);
      }
    }
  };

  return (
    <Grid container sx={{ height: "100vh" }}>
      {/* Blue Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: "#000075",
          color: "white",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          align="left"
          sx={{
            fontSize: "36px",
            color: "#ffffff",
            marginTop: "122px",
            fontSize: "46px",
            marginLeft: "54px",
          }}
        >
          Sitemap Generator
        </Typography>
        <Box
          component="p"
          sx={{
            fontSize: "16px",
            color: "#ffffff",
            marginLeft: "54px",
            width: "80%",
          }}
        >
          Signup to streamline your site’s structure. Organize and generate
          sitemaps effortlessly. Signup to streamline your site’s structure.
          Organize and generate sitemaps effortlessly...!
        </Box>
      </Grid>

      {/* Signup Box Section */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper
          sx={{ padding: 4, width: "55%", maxWidth: "100%", boxShadow: "none" }}
        >
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            align="left"
            sx={{ fontSize: "36px", color: " #000075" }}
          >
            Sitemap Generator Signup
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ marginY: 2 }}
            />
            {errors.username && (
              <Typography color="error" variant="body2">
                {errors.username}
              </Typography>
            )}
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginY: 2 }}
            />
            {errors.password && (
              <Typography color="error" variant="body2">
                {errors.password}
              </Typography>
            )}
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={cpassword}
              onChange={(e) => setCpassword(e.target.value)}
              sx={{ marginY: 2 }}
            />
            {errors.cpassword && (
              <Typography color="error" variant="body2">
                {errors.cpassword}
              </Typography>
            )}
            <Box sx={{ marginY: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#000075",
                  padding: "6px",
                  fontSize: "16px",
                }}
              >
                Signup
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Register;
